.nav_container {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-evenly;
  background-color: black;
  align-items: center;
}

.nav_container a {
  text-decoration: none;
  color: aliceblue;
  font-size: 1.5rem;
  text-align: center;
}

@media(max-width: 800px){
  .nav_container a {
    font-size: 1.2rem;
  }
}