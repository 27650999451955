.carousel {
  width: 100%;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-slide {
  width: 75%;
}

.slide-arrow {
  font-size: 4rem;
}