.crew_container {
  text-align: center;
  font-weight: 400;
  margin-top: 2rem;
}
.verticalBar {
  border-left: 3px solid black;
  height: 200px;
  margin-left: 10px;
  position: relative;
  top: 20px;
} 

.crew_container h1 {
  font-size: 3rem;
  font-weight: bold;
  line-height: 3.7rem;
  text-decoration: underline;
}

.crew_container h2 {
  font-size: 1.6rem;
}

.crew_container h3 {
  font-size: 2.2rem;
  font-weight: bold;
  text-decoration: underline;
}

.crew_container p {
  line-height: 1.3rem;
  font-size: 1rem;
}

.crew_container img {
  height: 205px;
  width: 205px;
  margin-top: 2.5rem;
}

.crew_top {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.crew_bot div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media(max-width: 800px){
  
.crew_container h1 {
  font-size: 2rem;
  line-height: 2.7rem;
}

.crew_container h2 {
  font-size: 1.2rem;
}

.crew_container h3 {
  font-size: 1.7rem;
}

.crew_container p {
  line-height: 1.3rem;
  font-size: .8rem;
}

.crew_container img {
  height: 150px;
  width: 150px;
}

.crew_bot div {
  flex-wrap: wrap;
}
}