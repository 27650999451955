.thanks_container{
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.thanks_container h2{
  margin-bottom: 1rem;
  text-decoration: underline;
  font-weight: bold;
  font-size: 3rem;
}

.thanks_rows{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.thanks_rows p{
  width: 15%;
  font-size: 1.2rem;
}

.thanks_footer {
  margin-top: 2rem;
}

.thanks_footer P{
  font-weight: bold;
  font-size: 1.4rem;
}

@media(max-width: 800px){
  .thanks_container{
    width: 100%;
  }
  .thanks_rows p {
    width: 48%;
  }
}