.landing_container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
}

.landing_container img {
  margin-top: 2rem;
  width: 82%;
  display: block;
}

.landing_overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 82%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

.landing_overlay img {
  width: 20%;
}

/* Mobile */
@media(max-width: 800px){
  .landing_container img {
    width: 95%;
  }

  .landing_overlay {
    width: 95%;
  }

  .landing_overlay img {
    width: 25%;
  }
}
